<template>
  <router-link
    v-bind:to="{ name: 'programIntro', params: { programId: program.id } }"
    class="program-discover-card"
  >
    <div
      v-if="program.image && program.name"
      class="program-discover-card-item"
      v-bind:style="{ backgroundImage: `url('${program.image}?w=600&q=80')` }"
      v-bind:title="program.name"
    >
      <category-label
        v-bind:category="program.categories[0]"
        class="program-discover-card-category"
      />

      <div class="program-discover-card-content">
        <div v-if="program.name" class="program-discover-card__title">
          {{ program.name }}
        </div>

        <div
          v-if="program.trainers[0]"
          class="program-discover-card-trainer"
        >
          {{ program.trainers[0].name }}
        </div>
      </div>
    </div>
  </router-link>
</template>

<script>
import CategoryLabel from '@/components/CategoryLabel';

export default {
  components: {
    CategoryLabel,
  },

  props: {
    program: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss">
@import "@/scss/lib";

.program-discover-card {
  margin-bottom: $spacing-sm;
  cursor: pointer;

  .program-discover-card-item {
    background-color: $color-beige;
    background-size: cover;
    background-position: center center;
    border-radius: $border-radius;
    height: $card-discover-height;
    position: relative;
    width: 100%;

    @media (min-width: $breakpoint-md) {
      height: $card-discover-height-desktop;
    }

    .program-discover-card-category {
      position: absolute;
      top: 1rem;
      left: 1rem;
    }

    .program-discover-card-content {
      background: linear-gradient(-180deg, rgba(0, 0, 0, 0) 1%, rgb(0, 0, 0) 97%);
      border-radius: 0 0 $border-radius $border-radius;
      bottom: 0;
      left: 0;
      padding: 1rem;
      position: absolute;
      width: 100%;

      .program-discover-card__title {
        @include paragraph--bold;
        margin: 0 0 rem(4px) 0;
        color: $color-white;
      }

      .program-discover-card-trainer {
        color: $color-white;
      }
    }
  }
}
</style>
