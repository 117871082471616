<template>
  <div class="workoutRecipes">
    <div class="workoutRecipes__content">
      <div class="workoutRecipes__title">
        {{ $t('programDetail.recipesTitle', { period }) }}
      </div>

      <p class="workoutRecipes__body">
        {{ $t('programDetail.recipesBody') }}
      </p>

      <base-button
        v-bind:to="{ name: 'programRecipes' }"
        modifiers="secondary"
      >
        {{ $t('programDetail.recipesButtonLabel') }}
      </base-button>
    </div>

    <div class="workoutRecipes__images">
      <img
        src="@/assets/program/fruit.svg"
        class="workoutRecipes__illustration"
      />

      <img
        v-for="recipe in filteredRecipes"
        v-bind:key="recipe.id"
        v-bind:src="`${recipe.image}?w=200&q=80`"
        v-bind:alt="recipe.name"
        class="workoutRecipes__image"
      />
    </div>
  </div>
</template>

<script>
import BaseButton from '@/components/BaseButton';

export default {
  components: {
    BaseButton,
  },

  props: {
    recipes: {
      type: Array,
      required: true,
    },

    period: {
      type: Number,
      required: true,
    },
  },

  computed: {
    filteredRecipes() {
      return this.recipes.slice(0, 3);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/lib";

.workoutRecipes {
  display: block;
  border-radius: 10px;

  @include desktop {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: url("../../assets/program/voedingschema.png") no-repeat 70% 50%;
    background-size: contain;
  }
}

.workoutRecipes__content {
  margin: 0 0 2rem 0;

  @include desktop {
    margin: 0;
  }
}

.workoutRecipes__title {
  @include heading-4;
  margin: 0 0 $spacing-xs 0;
  color: $color-black;
}

.workoutRecipes__body {
  @include small;
  margin: 0 0 1.5rem 0;

  @include desktop {
    margin-right: 120px;
  }
}

.workoutRecipes__images {
  display: inline-flex;
  position: relative;

  @include desktop {
    display: flex;
    justify-content: flex-end;
  }
}

.workoutRecipes__image {
  margin: 0 8px 0 0;
  width: 98px;
  height: 98px;
  border-radius: 10px;
  object-fit: cover;

  &:last-child {
    margin: 0;
  }

  @include desktop {
    width: 110px;
    height: 110px;
  }

  @media(min-width: 1024px) {
    width: 152px;
    height: 152px;
  }
}

.workoutRecipes__illustration {
  position: absolute;
  bottom: -10px;
  right: -45px;
  width: 80px;

  @include desktop {
    width: auto;
    right: auto;
    bottom: -20px;
    left: -120px;
  }
}
</style>
