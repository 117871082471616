<template>
  <div
      class="workoutTracker"
      v-if="champ"
  >
    <div class="workoutTracker__content">
      <div class="workoutTracker__description">
        <base-heading
            type="4"
            class="workoutTracker__title"
        >
          {{ $t('programDetail.workoutTrackerTitle') }}
        </base-heading>

        <p class="workoutTracker__body" v-html-safe="$t('programDetail.workoutTrackerBody')"></p>
      </div>
      <responsive-image
          v-bind:image-mobile="`${require('@/assets/program/champ-desktop.png')}, ${require('@/assets/program/champ-desktop.png')} 2x`"
          v-bind:image-tablet="`${require('@/assets/program/champ-desktop.png')}, ${require('@/assets/program/champ-desktop.png')} 2x`"
          picture-classes="workoutTracker__image"
      />
    </div>
  </div>
</template>

<script>
import BaseHeading from '@/components/BaseHeading';
import ResponsiveImage from '@/components/ResponsiveImage';
import {mapState} from "vuex";

export default {
  components: {
    BaseHeading,
    ResponsiveImage,
  },

  computed: {
    ...mapState('experimental', ['champ']),
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/lib";

.workoutTracker {
  padding: 1rem;
  background: $color-beige;
  border-radius: $border-radius;

  @include desktop {
    display: flex;
    align-items: center;
    padding: 2rem;
  }
}

.workoutTracker__content {
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  @media (min-width: 48rem) {
    flex: 1 1 auto;
    width: 50%;
  }

  @media (min-width: 62rem) {
    width: 60%;
  }

  .workoutTracker__description {
    flex: 1;
    align-self: flex-start;

    .workoutTracker__title {
      margin: 0 0 1rem 0;
      color: $color-black;
    }

    .workoutTracker__link {
      align-items: center;
      display: flex;
    }

    .workoutTracker__linkIcon {
      margin: 0 8px 0 0;
    }

    .workoutTracker__body {
      @include paragraph;
      margin: 0 1.5rem 0 0;
      flex: 1;
    }
  }

  .workoutTracker__image {
    flex: 1;
    max-width: 25%;
    transform: rotate(5deg);
    //margin: 0 2rem 0 0;

    //@media (min-width: 48rem) {
    //  flex: 1 1 auto;
    //  margin: 0 0 0 2rem;
    //  width: 20%;
    //}
    //
    //@media (min-width: 62rem) {
    //  width: 1%;
    //}
  }
}
</style>
