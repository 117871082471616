<template>
  <base-row>
    <base-column modifiers="md-4">
      <div class="workoutSection__title">
        {{ $t('programDetailWorkout.title', { weekNumber: currentPeriodNumber }) }}
      </div>

      <p
        v-if="currentPeriod.description"
        class="workoutSection__body"
      >
        {{ currentPeriod.description }}
      </p>

      <div class="workoutSection__progress">
        <Progressbar
          v-bind:percentage="progressBarPercentage"
          v-bind:height="8"
          class="workoutSection__progressbar"
        />

        <div>
          {{ currentWorkoutDescription }}

          <span v-if="!isPeriodCompleted && currentActiveWorkout">
            - {{ currentActiveWorkout.name }}
          </span>
        </div>
      </div>

      <div class="workoutSection__buttonWrapper">
        <base-button
          v-if="!isProgramCompleted && !isPeriodLocked"
          v-bind:to="nextWorkoutButtonParams"
          v-on:click="onNextWorkoutButtonClick($event)"
          modifiers="primary sm"
          class="workoutSection__button"
        >
          {{ nextWorkoutButtonLabel }}
        </base-button>

        <base-button
          v-if="champ && !isTrackerConnected"
          v-bind:to="nextWorkoutButtonParams"
          v-on:click="onTrackerButtonClick($event)"
          modifiers="primary sm"
          class="workoutSection__button"
        >
          {{ $t('programDetailWorkout.trackerButtonLabel') }}
        </base-button>

        <base-button
          v-if="isProgramCompleted"
          v-bind:show-spinner="isProgramRestarting"
          v-bind:disabled="isProgramRestarting"
          modifiers="primary sm"
          class="workoutSection__button"
          v-on:click="onRestartProgramClick"
        >
          {{ $t('programDetailWorkout.restartButtonLabel') }}
        </base-button>
      </div>
    </base-column>

    <base-column modifiers="md-7 md-offset-1">
      <div class="workoutSection__workouts">
        <div
          v-for="(workout, index) in workouts"
          v-bind:key="index"
          class="workoutSection__workout"
        >
          <workout-card
            v-if="!isLoading"
            v-bind:workout="workout"
            v-bind:period-id="currentPeriod.id"
            v-bind:allowed-active="workout.allowedActive"
          />
        </div>
      </div>
    </base-column>
  </base-row>
</template>

<script>
import { defineAsyncComponent, markRaw } from 'vue';
import {mapActions, mapGetters, mapState} from 'vuex';
import BaseRow from '@/components/grid/BaseRow';
import BaseColumn from '@/components/grid/BaseColumn';
import WorkoutCard from '@/components/program/WorkoutCard';
import Progressbar from '@/components/Progressbar';
import BaseButton from '@/components/BaseButton';
import { ModalBus } from '@/eventBus';

const WorkoutModal = markRaw(defineAsyncComponent({
  loader: () => import('@/components/workout/WorkoutModal' /* webpackChunkName: "workoutModal" */),
}));

const ChampModal = markRaw(defineAsyncComponent({
  loader: () => import('@/components/profile/ChampModal' /* webpackChunkName: "medisanaModal" */),
}));

export default {
  components: {
    BaseRow,
    BaseColumn,
    BaseButton,
    Progressbar,
    WorkoutCard,
  },

  props: {
    period: {
      type: Object,
      required: true,
    },

    program: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      isLoading: true,
      isProgramRestarting: false,
    };
  },

  computed: {
    ...mapState('member', ['member']),
    // ...mapGetters('member', ['profile']),
    ...mapGetters('program', ['currentProgram']),
    ...mapGetters('period', ['currentPeriod', 'currentPeriodNumber']),
    ...mapState('experimental', ['champ']),

    workouts() {
      // NOTE this workaround is to prevent two workout cards show as active
      const workouts = [...this.currentPeriod.workouts];

      for (let i = workouts.length - 1; i >= 0; i -= 1) {
        const workout = workouts[i];
        workout.allowedActive = false;

        if (workout.status === 'active' || workout.status === 'in_progress') {
          workout.allowedActive = true;
          break;
        }
      }

      return workouts;
    },

    currentActiveWorkout() {
      const { workouts } = this.currentPeriod;

      return workouts.find(({ status }) => status === 'active' || status === 'in_progress');
    },

    isPeriodLocked() {
      const { workouts } = this.currentPeriod;
      const allWorkoutsLocked = workouts.every(({ status }) => status === 'locked');

      return allWorkoutsLocked;
    },

    isPeriodCompleted() {
      const { workouts } = this.currentPeriod;
      const allWorkoutsDone = workouts.every(({ status }) => status === 'done');

      return allWorkoutsDone;
    },

    isProgramCompleted() {
      const isLastPeriod = this.currentPeriodNumber === this.currentProgram.periods.length;

      return isLastPeriod && this.isPeriodCompleted;
    },

    isTrackerConnected() {
      return this.member.integrations.champ;
    },

    isCurrentActivePeriod() {
      return this.currentProgram.active_period === this.currentPeriod.id;
    },

    nextWorkoutButtonParams() {
      if (this.isPeriodCompleted || !this.isCurrentActivePeriod) {
        return {
          name: 'programWorkout',
          params: { periodId: this.currentProgram.active_period },
        };
      }

      return {};
    },

    nextWorkoutButtonLabel() {
      if (this.isPeriodCompleted || !this.isCurrentActivePeriod) {
        const { periods, active_period } = this.currentProgram;
        const activePeriodNumber = periods.findIndex(({ id }) => id === active_period) + 1;

        return this.$t('programDetailWorkout.nextPeriodButtonLabel', { period: activePeriodNumber });
      }

      return this.$t('programDetailWorkout.nextWorkoutButtonLabel');
    },

    progressBarPercentage() {
      const { workouts } = this.currentPeriod;
      const periodPercentage = 100 / this.currentProgram.periods.length;
      const numWorkouts = workouts.length;
      const numWorkoutsCompleted = workouts.filter(({ status }) => status === 'done').length;
      const workoutPercentage = periodPercentage - (periodPercentage / numWorkouts) * numWorkoutsCompleted;

      return periodPercentage * parseInt(this.currentPeriodNumber) - workoutPercentage;
    },

    currentWorkoutDescription() {
      if (this.isProgramCompleted) {
        return this.$t('programDetailWorkout.programCompletedLabel');
      }

      return this.$t('programCard.periodXofY', {
        x: this.currentPeriodNumber,
        y: this.currentProgram.periods.length,
      });
    }
  },

  async created() {
    const today = new Date();
    const fetchBodyData = {
      year: today.getFullYear(),
      month: today.getMonth() + 1,
    };

    await this.fetchBody(fetchBodyData);

    this.isLoading = false;
  },

  methods: {
    ...mapActions('member', ['fetchBody']),

    ...mapActions({
      restartProgram: 'program/restart',
    }),

    onNextWorkoutButtonClick(event) {
      if (this.isPeriodCompleted) {
        return;
      }

      event.preventDefault();

      ModalBus.emit('open', {
        component: WorkoutModal,
        props: {
          workoutId: this.currentProgram.active_workout.id,
          periodId: this.currentProgram.active_period,
        },
      });
    },

    onTrackerButtonClick(event) {
      event.preventDefault();

      ModalBus.emit('open', {
        component: ChampModal,
        props: {
        },
      });
    },

    async onRestartProgramClick() {
      this.isProgramRestarting = true;

      await this.restartProgram(this.currentProgram.id);

      if (Number(this.$route.params.periodId) !== this.currentProgram.active_period) {
        this.$router.push({
          name: 'programWorkout',
          params: {
            programId: this.currentProgram.id,
            periodId: this.currentProgram.active_period,
          },
        });
      }

      this.isProgramRestarting = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/lib";

.workoutSection__button {
  display: inline-flex;

  &:not(:first-child) {
    margin-left: 1rem;
  }
}

.workoutSection__title {
  @include heading-4;
  margin: 0 0 rem(16px) 0;
  color: $color-text;
}

.workoutSection__body {
  @include small;
  margin: 0 0 rem(36px) 0;
}

.workoutSection__workouts {
  padding: rem(20px) 0;
  border-top: 1px solid rgba(10, 29, 51, 0.1);
  border-bottom: 1px solid rgba(10, 29, 51, 0.1);

  @include desktop {
    padding: rem(20px) 0 0 0;
    border: none;
  }
}

.workoutSection__workout {
  margin: 0 0 rem(4px) 0;
}

.workoutSection__progress {
  @include note;
  margin: 0 0 rem(24px) 0;
}

.workoutSection__progressbar {
  margin-bottom: rem(8px);
  width: 100%;
}
</style>
