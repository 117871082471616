<template>
  <router-link
    v-bind:to="{ name: 'recipes' }"
    class="recipesDiscoverCard"
  >
    <div class="recipesDiscoverCard__content">
      <div class="recipesDiscoverCard__title">
        Meer dan 800 gezonde recepten voor ieder moment.
      </div>

      <base-button modifiers="secondary">
        Bekijk alle recepten
      </base-button>
    </div>

    <responsive-image
      v-bind:image-mobile="`${require('@/assets/program/banner-all-recipes-mobile.jpg')}`"
      v-bind:image-desktop="`${require('@/assets/program/banner-all-recipes-desktop.jpg')}`"
      picture-classes="recipesDiscoverCard__image"
    />
  </router-link>
</template>

<script>
import BaseButton from '@/components/BaseButton';
import ResponsiveImage from '@/components/ResponsiveImage';

export default {
  components: {
    BaseButton,
    ResponsiveImage,
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/lib";

.recipesDiscoverCard {
  @media(min-width: 1024px) {
    display: flex;
    align-items: center;
  }
}

.recipesDiscoverCard__content {
  margin: 2rem 0;

  @media(min-width: 1024px) {
    margin: 0;
    padding: 0 1rem 0 0;
    width: 35%;
  }
}

.recipesDiscoverCard__title {
  @include heading-4;
  margin: 0 0 1rem 0;
}

.recipesDiscoverCard__image {
  width: 100%;

  @media(min-width: 1024px) {
    width: 65%;
  }
}
</style>
