<template>
  <picture
    v-bind:class="{
      responsiveImage: true,
      [pictureClasses]: true,
    }"
  >
    <source
      v-if="imageDesktop"
      v-bind:srcset="imageDesktop"
      media="(min-width: 1024px)"
    />

    <source
      v-if="imageTablet"
      v-bind:srcset="imageTablet"
      media="(min-width: 768px)"
    />

    <source
      v-if="imageMobile"
      v-bind:srcset="imageMobile"
      media="(min-width: 0px)"
    />

    <img
      v-bind:src="defaultImage"
      v-bind:alt="alt"
      class="responsiveImage__image"
    />
  </picture>
</template>

<script>
export default {
  props: {
    imageMobile: {
      type: String,
      default: '',
    },

    imageTablet: {
      type: String,
      default: '',
    },

    imageDesktop: {
      type: String,
      default: '',
    },

    alt: {
      type: String,
      default: '',
    },

    pictureClasses: {
      type: String,
      default: '',
    }
  },

  computed: {
    defaultImage() {
      const srcset = this.imageMobile || this.imageTablet || this.imageDesktop;
      const image = srcset.split(',')[0];

      return image;
    }
  }
};
</script>

<style lang="scss" scoped>
.responsiveImage {
  display: block;
  font-size: 0;
  overflow: hidden; // this forces a potential border radius on the image child
}

.responsiveImage__image {
  display: block;
  width: 100%;
  height: auto;
}
</style>
