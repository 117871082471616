export default (memberWeight, workoutDurationInMinutes, workoutCalories) => {
  if (!memberWeight) {
    return '***';
  }

  const burnedCalories = Math.round(memberWeight * workoutCalories * workoutDurationInMinutes);

  if (isNaN(burnedCalories)) {
    return '***';
  }

  return burnedCalories;
}