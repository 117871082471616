<template>
  <div class="programDetailWorkoutPage">
    <base-section>
      <base-grid modifiers="maxWidth" class="programDetailWorkoutPage__workouts">
        <workout-section
          v-bind:program="currentProgram"
          v-bind:period="currentPeriod"
        />
      </base-grid>
    </base-section>

    <base-section>
      <base-grid modifiers="maxWidth">
        <base-row>
          <base-column>
            <program-workout-recipes
              v-bind:recipes="currentPeriod.recipes"
              v-bind:period="currentPeriodNumber"
              class="programDetailWorkoutPage__recipes"
            />
          </base-column>
        </base-row>
      </base-grid>
    </base-section>

    <base-section v-if="currentPeriod.extras.length">
      <base-grid modifiers="maxWidth">
        <base-row>
          <base-column>
            <extra-section
              v-bind:extra="currentPeriod.extras"
              class="programDetailWorkoutPage__extras"
            />
          </base-column>
        </base-row>
      </base-grid>
    </base-section>

    <base-section>
      <base-grid modifiers="maxWidth">
        <base-row>
          <base-column>
            <program-workout-planner class="programDetailWorkoutPage__planner" />
          </base-column>
        </base-row>
      </base-grid>
    </base-section>

    <base-section v-if="champ">
      <base-grid modifiers="maxWidth">
        <base-row>
          <base-column>
            <program-workout-tracker class="programDetailWorkoutPage__tracker" />
          </base-column>
        </base-row>
      </base-grid>
    </base-section>

    <base-section v-if="discoverPrograms.length">
      <base-grid modifiers="maxWidth">
        <base-row>
          <base-column>
            <program-discover-section v-bind:programs="discoverPrograms" />
          </base-column>
        </base-row>
      </base-grid>
    </base-section>
  </div >
</template>

<script>
import {mapGetters, mapState} from 'vuex';
import BaseSection from '@/components/grid/BaseSection';
import BaseGrid from '@/components/grid/BaseGrid';
import BaseRow from '@/components/grid/BaseRow';
import BaseColumn from '@/components/grid/BaseColumn';
import BaseHeading from '@/components/BaseHeading';
import WorkoutSection from '@/components/program/WorkoutSection';
import ExtraSection from '@/components/program/ExtraSection';
import ProgramDiscoverSection from '@/components/program/ProgramDiscoverSection';
import ProgramWorkoutRecipes from '@/components/program/ProgramWorkoutRecipes';
import ProgramWorkoutPlanner from '@/components/program/ProgramWorkoutPlanner';
import ProgramWorkoutTracker from '@/components/program/ProgramWorkoutTracker';
import sortRandom from '@/utils/sortRandom';

export default {
  components: {
    BaseSection,
    BaseGrid,
    BaseRow,
    BaseColumn,
    ProgramWorkoutRecipes,
    ProgramWorkoutPlanner,
    ProgramWorkoutTracker,
    ProgramDiscoverSection,
    ExtraSection,
    BaseHeading,
    WorkoutSection,
  },

  computed: {
    ...mapGetters('program', ['currentProgram', 'inactivePrograms']),
    ...mapGetters('period', ['currentPeriod', 'currentPeriodNumber']),
    ...mapState('experimental', ['champ']),

    discoverPrograms() {
      const maxNumPrograms = 2;
      const programs = [];

      this.currentProgram.categories.forEach(category => {
        this.inactivePrograms.forEach(inactiveProgram => {
          inactiveProgram.categories
            .filter(({ slug }) => slug === category.slug)
            .filter(() => !programs.includes(inactiveProgram))
            .forEach(() => programs.push(inactiveProgram));
        });
      });

      if (programs.length > maxNumPrograms) {
        return programs
          .sort(sortRandom)
          .slice(0, maxNumPrograms);
      }

      return programs;
    },
  },
};
</script>

<style lang="scss" scoped>
.programDetailWorkoutPage__extras {
  margin-top: 4.5rem;
}
</style>
