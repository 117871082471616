<template>
  <div class="workoutPlanner">
    <div class="workoutPlanner__content">
      <base-heading
        type="4"
        class="workoutPlanner__title"
      >
        {{ $t('programDetail.workoutPlannerTitle') }}
      </base-heading>

      <div class="workoutPlanner__body">
        {{ $t('programDetail.workoutPlannerBody') }}
      </div>

      <a
        href="https://downloads.fitchannel.com/2024/weekplannervoorbeeld-2024.pdf"
        target="_blank"
        rel="noopener noreferrer"
        class="workoutPlanner__link"
      >
        <font-awesome-icon
          icon="cloud-arrow-down"
          class="workoutPlanner__linkIcon"
        />
        {{ $t('programDetail.workoutPlannerLinkLabel') }}
      </a>
    </div>

    <responsive-image
      v-bind:image-mobile="`${require('@/assets/program/weekplanner-mobile.jpg')}, ${require('@/assets/program/weekplanner-mobile@2x.jpg')} 2x`"
      v-bind:image-tablet="`${require('@/assets/program/weekplanner-desktop.jpg')}, ${require('@/assets/program/weekplanner-desktop@2x.jpg')} 2x`"
      picture-classes="workoutPlanner__image"
    />
  </div>
</template>

<script>
import BaseHeading from '@/components/BaseHeading';
import ResponsiveImage from '@/components/ResponsiveImage';

export default {
  components: {
    BaseHeading,
    ResponsiveImage,
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/lib";

.workoutPlanner {
  padding: 1rem;
  background: $color-beige;
  border-radius: $border-radius;

  @include desktop {
    display: flex;
    align-items: center;
    padding: 2rem;
  }
}

.workoutPlanner__content {
  @media (min-width: 48rem) {
    flex: 1 1 auto;
    width: 50%;
  }

  @media (min-width: 62rem) {
    width: 60%;
  }
}

.workoutPlanner__image {
  margin: 2rem 0 0 0;
  max-width: 100%;
  border-radius: $border-radius;

  @media (min-width: 48rem) {
    flex: 1 1 auto;
    margin: 0 0 0 2rem;
    width: 50%;
  }

  @media (min-width: 62rem) {
    width: 40%;
  }
}

.workoutPlanner__title {
  margin: 0 0 1rem 0;
  color: $color-black;
}

.workoutPlanner__body {
  @include paragraph;
  margin: 0 0 1.5rem 0;
}

.workoutPlanner__link {
  align-items: center;
  display: flex;
}

.workoutPlanner__linkIcon {
  margin: 0 8px 0 0;
}
</style>
