<template>
  <div
    v-bind:class="{
      'workoutCard': true,
      'workoutCard--allowedActive': allowedActive,
      [`workoutCard--${workout.status}`]: true,
    }"
    v-on:click="onClick"
  >
    <div class="workoutCard__inner">
      <div class="workoutCard__imageWrapper">
        <img
          v-bind:src="`${workout.image}?w=150&q=80&ar=16:9&fit=crop`"
          class="workoutCard__image"
        />

        <rating-icon
          v-if="workout.feedback"
          class="workoutCard__ratingIcon"
          v-bind:rating="workout.feedback"
        />

        <Progressbar
          v-if="workout.status !== 'locked'"
          v-bind:percentage="progressBarPercentage"
          v-bind:height="6"
          background-color="#a6abb0"
          class="workoutCard__progressbar"
        />
      </div>

      <div class="workoutCard__content">
        <span class="workoutCard__name">
          {{ workout.name }}
        </span>

        <div class="workoutCard__information">
          <span
            v-if="workout.duration"
            class="workoutCard__duration"
          >
            {{ duration }}
          </span>

          <span
            v-if="intensity"
            class="workoutCard__intensity"
          >
            {{ intensity }}
          </span>

          <span
            v-if="workout.status === 'done' && !isNaN(calories)"
            class="workoutCard__calories"
          >
            {{ calories }} cal
          </span>

          <span
            v-if="workout.status === 'done'"
            class="workoutCard__points"
          >
            500 pt
          </span>

          <span
            v-if="workout.status === 'done'"
            class="workoutCard__date"
          >
            {{ date }}
          </span>
        </div>
      </div>

      <div
        v-bind:class="[
          'workoutCard__status',
          `workoutCard__status--${workout.status}`,
        ]"
      >
        <font-awesome-icon
          v-if="icon"
          v-bind:icon="['fas', icon]"
          class="workoutCard__statusIcon"
        />
      </div>
    </div>

    <p
      class="workoutCard__body"
      v-html-safe="workout.description"
    />
  </div>
</template>

<script>
import { defineAsyncComponent, markRaw } from 'vue';
import { mapState, mapGetters } from 'vuex';
import moment from 'moment';
import RatingIcon from '@/components/RatingIcon';
import Progressbar from '@/components/Progressbar';
import calculateCalories from '@/utils/calculateCalories';
import { ModalBus } from '@/eventBus';

const WorkoutModal = markRaw(defineAsyncComponent({
  loader: () => import('@/components/workout/WorkoutModal' /* webpackChunkName: "workoutModal" */),
}));

export default {
  components: {
    RatingIcon,
    Progressbar,
  },

  props: {
    workout: {
      type: Object,
      required: true,
    },

    allowedActive: {
      type: Boolean,
      default: false,
    },

    periodId: {
      type: Number,
      required: true,
    },
  },

  computed: {
    ...mapState('member', ['body']),
    ...mapGetters('program', ['currentProgram']),
    ...mapGetters('period', ['currentPeriod']),

    icon() {
      switch (this.workout.status) {
        case 'done':
        case 'skipped':
          return 'check';

        case 'active':
        case 'in_progress':
          return 'play';

        case 'loading':
          return 'spinner-third';

        default:
          return 'lock';
      };
    },

    intensity() {
      const { intensities } = this.currentPeriod;
      const { intensity: workoutIntensity } = this.workout;
      const intensity = intensities.find(({ intensity }) => intensity === workoutIntensity);

      if (intensity) {
        return intensity.name;
      }

      return '-';
    },

    duration() {
      return `${Math.ceil(this.workout.duration / 60)} min`;
    },

    calories() {
      const { duration, calories } = this.workout;
      const durationInMinutes = duration / 60;
      const lengthBodyHistory = this.body.history.length;
      const weight = lengthBodyHistory ? this.body.history[lengthBodyHistory - 1].weight : null;

      return calculateCalories(weight, durationInMinutes, calories);
    },

    date() {
      return moment(this.workout.finished_at).fromNow();
    },

    progressBarPercentage() {
      if (this.workout.status == 'done') {
        return 100;
      }

      return Math.ceil((100 * this.workout.video_position) / this.workout.duration);
    },
  },

  methods: {
    onClick() {
      ModalBus.emit('open', {
        component: WorkoutModal,
        props: {
          workoutId: this.workout.id,
          periodId: this.periodId,
        },
      });
    },
  },
};
</script>

<style lang="scss">
@import "@/scss/lib";

.workoutCard__body {
  > * {
    @include small;
  }
}
</style>

<style lang="scss" scoped>
@import "@/scss/lib";

.workoutCard {
  margin: 0 -#{$gridGutterWidth / 2};
  padding: rem(16px);
  padding-right: rem(20px);
  cursor: pointer;

  &.workoutCard--allowedActive.workoutCard--active,
  &.workoutCard--allowedActive.workoutCard--in_progress {
    background: $color-beige;
  }

  @include desktop {
    margin: 0;
    border-radius: 16px;
  }
}

.workoutCard__inner {
  display: flex;
  align-items: center;
  justify-content: center;
}

.workoutCard__imageWrapper {
  flex: 0 0 auto;
  position: relative;
  margin: 0 1rem 0 0;
  height: rem(80px);
  border-radius: 10px;
  overflow: hidden;
}

.workoutCard__image {
  display: block;
  height: 100%;
}

.workoutCard__ratingIcon {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -16px 0 0 -16px;
  width: 32px;
  height: 32px;
}

.workoutCard__progressbar {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  z-index: 1;
}

.workoutCard__content {
  flex: 1 1 auto;
}

.workoutCard__name {
  @include paragraph;
  display: block;
  margin: 0 0 rem(3px) 0;
  color: $color-black;
}

.workoutCard__duration {
  @include note;
  color: $color-grey;
}

.workoutCard__intensity,
.workoutCard__calories,
.workoutCard__points,
.workoutCard__date {
  @include note;
  color: $color-grey;

  &:before {
    content: "•";
    margin: 0 rem(1px);
    color: $color-grey--light;
  }
}

.workoutCard__intensity {
  text-transform: capitalize;
}

.workoutCard__status {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  border: 2px solid transparent;
  border-radius: 50%;
  color: $color-white;

  &.workoutCard__status--active,
  &.workoutCard__status--in_progress {
    background-color: $color-green;
  }

  &.workoutCard__status--locked {
    border-color: $color-grey--light;
    background-color: $color-grey--light;
  }

  &.workoutCard__status--done,
  &.workoutCard__status--skipped {
    border-color: $color-white;
    background-color: $color-white;
    color: $color-green;
  }
}

.workoutCard__statusIcon {
  font-size: 14px;

  .workoutCard__status--done & {
    font-size: 26px;
  }
}

.workoutCard__body {
  display: none;

  .workoutCard--allowedActive.workoutCard--active & ,
  .workoutCard--allowedActive.workoutCard--in_progress & {
    display: block;
    margin: rem(16px) 0 0 0;
  }
}
</style>
