<template>
  <div class="extraSection">
    <mobile-slider>
      <mobile-slider-item
        v-for="(item, index) in extra"
        v-bind:key="index"
        mobile-width="75%"
        desktop-width="100%"
        class="extraSection__card"
      >
        <card-extra v-bind:extra="item" />
      </mobile-slider-item>
    </mobile-slider>
  </div>
</template>

<script>
import MobileSlider from '@/components/MobileSlider';
import MobileSliderItem from '@/components/MobileSliderItem';
import CardExtra from '@/components/program/CardExtra';

export default {
  components: {
    MobileSlider,
    MobileSliderItem,
    CardExtra,
  },

  props: {
    extra: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/lib";

.extraSection__card {
  @media (min-width: $breakpoint-md) {
    margin: 0 0 $spacing-md 0;
    padding: 0;

    &:last-child {
      margin: 0;
    }
  }
}
</style>
