<template>
  <div class="programDiscoverSection">
    <base-heading type="4">
      {{ $t('programDetail.discoverProgramsTitle') }}
    </base-heading>

    <div class="programDiscoverSection__programs">
      <card-discover-program
        v-for="(item, index) in programs"
        v-bind:key="index"
        v-bind:program="item"
        class="programDiscoverSection__program"
      />
    </div>

    <card-discover-recipes class="programDiscoverSection__recipes" />
  </div>
</template>

<script>
import BaseHeading from '@/components/BaseHeading';
import CardDiscoverProgram from '@/components/cards/CardDiscoverProgram';
import CardDiscoverRecipes from '@/components/cards/CardDiscoverRecipes';

export default {
  components: {
    BaseHeading,
    CardDiscoverRecipes,
    CardDiscoverProgram,
  },

  props: {
    programs: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/lib";

.programDiscoverSection {
  border-top: 1px solid rgba(#0a1d33, 0.1);
  padding-top: 2.5rem;
}

.programDiscoverSection__programs {
  display: flex;
  margin: 0 0 1rem 0;
}

.programDiscoverSection__program {
  flex: 0 0 calc(50% - 0.5rem);
  margin: 0 0.5rem;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }
}
</style>
