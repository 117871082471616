<template>
  <div
    class="extraCard"
    v-on:click="onClick"
  >
    <div class="extraCard__imageWrapper">
      <img
        v-bind:src="`${extra.image}?w=300&q=80`"
        class="extraCard__image"
      />
    </div>

    <div class="extraCard__content">
      <div class="extraCard__title">
        {{ extra.name }}
      </div>

      <p
        v-html-safe="extra.description"
        class="extraCard__description"
      />

      <span class="extraCard__link">
        {{ $t('programDetailExtra.readMoreLinkLabel') }}
      </span>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent, markRaw } from 'vue';
import { ModalBus } from '@/eventBus';

const ProgramDetailExtra = markRaw(defineAsyncComponent({
  loader: () => import('@/components/program/ProgramDetailExtra' /* webpackChunkName: "programDetailExtra" */),
}));

export default {
  props: {
    extra: {
      type: Object,
      required: true,
    },
  },

  methods: {
    onClick() {
      ModalBus.emit('open', {
        component: ProgramDetailExtra,
        props: {
          extraId: this.extra.id,
        },
      });
    },
  },
};
</script>

<style lang="scss">
@import "@/scss/lib";

.extraCard__description {
  p {
    @include label;
    display: none;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;

    @media (min-width: $breakpoint-md) {
      &:first-child {
        display: -webkit-box;
      }
    }

    &:first-child {
      display: block;
    }
  }

  ul {
    margin: 0.625rem 0 0 0;
  }

  li {
    @include label;
  }
}
</style>

<style lang="scss" scoped>
@import "@/scss/lib";

.extraCard {
  cursor: pointer;

  @include desktop {
    display: flex;
  }
}

.extraCard__imageWrapper {
  @include desktop {
    flex: 0 0 25%;
  }
}

.extraCard__image {
  border-radius: $border-radius-md;
  display: block;
  height: 9rem;
  object-fit: cover;
  position: relative;
  width: 100%;
  z-index: 1;
}

.extraCard__content {
  padding: $spacing-sm 0 0 0;

  @media (min-width: $breakpoint-md) {
    flex: 0 0 50%;
    padding: 0.25rem 0 0 $spacing-sm;
  }
}

.extraCard__title {
  @include paragraph--bold;
  color: $color-text;

  @media (min-width: $breakpoint-md) {
    margin-bottom: 0.625rem;
  }
}

.extraCard__description {
  @include label;
  display: none;
  margin-bottom: 0.625rem;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;

  @media (min-width: $breakpoint-md) {
    display: -webkit-box;
  }
}

.extraCard__link {
  @include label--bold;
  display: none;
  color: $color-text-link;
  text-decoration: underline;

  @media (min-width: $breakpoint-md) {
    display: block;
  }
}
</style>
